export var tableField = [
  { label: '序号', code: 'lineNumber', type: 'input', width: '' },
  {
    label: '物料凭证号',
    code: 'materialVoucherNo',
    width: '150',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          methods: {
            clk: () => {
              this.$emit('diashow', scope)
            }
          },
          template: '<span><el-button size="mini" type="text" @click="clk">' + scope.row.materialVoucherNo + '</el-button></span>'
        }
      }
    }
  },
  { label: '物料凭证行号', code: 'materialVoucherLineNumber', type: 'input', width: '110' },
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '110' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '100' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '130' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '160' },
  { label: '收货数量 ', code: 'quantityReceived', type: 'input', width: '' },
  { label: '对账数量 ', code: 'reconciledQuantity', type: 'input', width: '' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税金额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '未税金额', code: 'amountNotTaxedThisTime', type: 'amount', align: 'right', width: '120' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '税额', code: 'currentTaxAmount', type: 'amount', align: 'right', width: '' },
  { label: '修正含税金额', code: 'revisedAmountIncludingTax', type: 'amount', align: 'right', width: '135' },
  { label: '修正未税金额', code: 'reviseTheAmountNotTaxed', type: 'amount', align: 'right', width: '135' },
  { label: '修正税额', code: 'revisedTax', type: 'amount', align: 'right', width: '110' },
  {
    id: 'xzcy',
    label: '修正差异',
    type: 'function',
    width: '100',
    handle (index, row) {
      const mat = (parseFloat(row.revisedAmountIncludingTax) - parseFloat(row.amountIncludingTax)).toFixed(2)
      // eslint-disable-next-line eqeqeq
      if (mat == 0) {
        return mat
      } else {
        return `<span style='color:red'>${mat}</span>`
      }
    }
  },
  { label: '收货基地', code: 'base', type: 'input', width: '' },
  { label: '内部项目号', code: 'internalProjectNumber', type: 'input', width: '110' },
  { label: '内部项目标识', code: 'internalProjectIdentification', type: 'input', width: '110' },

  { label: '对账单抵消标记', code: 'systemStatementMark', type: 'input', width: '135' },
  { label: '网上发票抵消标记', code: 'networkStatementMark ', type: 'input', width: '140' },
  {
    label: '是否创建网上发票',
    code: 'isInvoice',
    type: 'function',
    width: '140',
    handle: (index, data) => {
      return data.isInvoice === 0 ? '是' : '否'
    }
  }

]
export var MaterialData = [
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '130' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '160' },
  { label: '我的物料编号', code: 'myItemNumber', type: 'input', width: '110' },
  { label: '我的物料描述', code: 'myMaterialDescription', type: 'input', width: '110' },
  { label: '收货数量 ', code: 'quantityReceived', type: 'input', width: '' },
  { label: '对账数量 ', code: 'reconciledQuantity', type: 'input', width: '' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税金额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '未税金额', code: 'amountNotTaxedThisTime', type: 'amount', align: 'right', width: '120' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '税额', code: 'currentTaxAmount', type: 'amount', align: 'right', width: '' },
  { label: '修正含税金额', code: 'revisedAmountIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '修正未税金额', code: 'reviseTheAmountNotTaxed', type: 'amount', align: 'right', width: '120' },
  { label: '修正税额 ', code: 'revisedTax', type: 'amount', align: 'right', width: '100' },
  {
    id: 'xzcy',
    label: '修正差异',
    type: 'function',
    width: '100',
    handle (index, row) {
      const mat = (parseFloat(row.revisedAmountIncludingTax) - parseFloat(row.amountIncludingTax)).toFixed(2)
      // eslint-disable-next-line eqeqeq
      if (mat == 0) {
        return mat
      } else {
        return `<span style='color:red'>${mat}</span>`
      }
    }
  }

]
export var tableFieldCy = [
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '110' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '100' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '' },
  { label: '本次对账数量 ', code: 'reconciledQuantity', type: 'input', width: '110' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税金额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '未税金额', code: 'amountNotTaxedThisTime', type: 'amount', align: 'right', width: '120' },
  { label: '修正含税金额', code: 'revisedAmountIncludingTax', type: 'amount', align: 'right', width: '' },
  { label: '修正未税金额', code: 'reviseTheAmountNotTaxed', type: 'amount', align: 'right', width: '' },
  { label: '修正税额', code: 'revisedTax', type: 'amount', align: 'right', width: '110' }
]

export var tableData = []
