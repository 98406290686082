<template>
  <div class="mainform">
      <!-- 采购对账单详情 -->
        <div class="mainHeader">对账单  <span @click="back" class="back">返回></span></div>
      <div class="form">
        <div class="header">对账内容
            <span class="showOff">
              <el-button @click="openContent" size="small" type="text">{{isShowContent?"展开":"收起"}}
                <i :class="[{'el-icon-arrow-down':isShowContent},{'el-icon-arrow-up':!isShowContent}]"></i>
              </el-button>
            </span>
          </div>
          <el-form  class="content bascform" v-if="isShowContent" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
            <div  class="col col4">
              <el-form-item label="对账单编号" >
                  <el-input v-model="form.number"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="供应商" >
                <span class="onlyText">
                  {{form.supplierCode+'-'+form.supplier}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="公司名称" >
                <span class="onlyText">
                  {{form.corporateCode+'-'+form.corporateName}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="开票方">
                <span class="onlyText">
                  {{form.billingPartyCode+'-'+form.billingParty}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="币种" >
                  <el-input v-model="form.currency"></el-input>
              </el-form-item>
            </div>
            <div class="col col4">
              <el-form-item label="对账单类型">
                <el-select v-model="form.statementType" >
                  <el-option label="正常采购" :value="0"></el-option>
                  <el-option label="分期付款" :value="1"></el-option>
                  </el-select>
              </el-form-item>
              </div>
            <div  class="col col4">
              <el-form-item label="含税总额" >
                <el-input v-model="form.invoiceAmountIncludingTax"></el-input>
              </el-form-item>
            </div>
            <div class="col col4">
              <el-form-item label="是否寄售">
                <el-select v-model="form.consignmentOrNot" >
                  <el-option label="是" :value="0"></el-option>
                  <el-option label="否" :value="1"></el-option>
                  </el-select>
              </el-form-item>
              </div>
            <div  class="col col4">
              <el-form-item label="未税总额" >
                <el-input v-model="form.noTaxInvoiceAmount"></el-input>
              </el-form-item>
            </div>
            <div class="col col4">
              <el-form-item label="对账单状态">
                <el-select v-model="form.status" >
                  <el-option label="待确认" :value="0"></el-option>
                  <el-option label="已完成" :value="1"></el-option>
                  <el-option label="审批中" :value="2"></el-option>
                  <el-option label="驳回" :value="3"></el-option>
                  </el-select>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="总税额" >
                <el-input v-model="form.invoiceAmount"></el-input>
              </el-form-item>
            </div>
            <div class="col col4">
              <el-form-item label="网上发票状态">
                <el-select v-model="form.createOnlineInvoice" >
                  <el-option label="完全创建" :value="0"></el-option>
                  <el-option label="未创建" :value="1"></el-option>
                  <el-option label="部分创建" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col col4" v-if="xzcyzeshow">
              <el-form-item label="修正差异总额">
                <span class="onlyText">
                  {{xzcyze}}
                </span>
              </el-form-item>
            </div>
            <div class="col col4">
              <el-form-item label="备注">
                <el-input  v-model="form.remarks"></el-input>
              </el-form-item>
            </div>
            <div  class="col col8">
              <el-form-item label="差异原因" >
                <span class="onlyText">
                  {{form.differenceReason}}
                </span>
              </el-form-item>
            </div>
          </el-form>
          <div class="header">对账明细
            <span class="showOff">
              <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
                <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
              </el-button>
            </span>
          </div>
              <global-table v-if="isclose" class="bascform" @diashow="diaShow" max-height="350" :tableField="tableField" :tableData="tableDataDetail" ></global-table>
          <div class="header">物料汇总
            <span class="showOff">
              <el-button @click="show" size="small" type="text">{{isShow?"展开":"收起"}}
                <i :class="[{'el-icon-arrow-down':isShow},{'el-icon-arrow-up':!isShow}]"></i>
              </el-button>
            </span>
          </div>
              <global-table v-if="isShow" class="bascform"  max-height="350" :tableField="MaterialData" :tableData="MaterialtableData" ></global-table>
          <div v-if="isAppr" class="header">对账差异</div>
              <global-table v-if="isAppr"  @diashow="diaShow" class="bascform" max-height="350" :tableField="tableFieldCy" :tableData="tableData" ></global-table>
      <approval-mind></approval-mind>
      </div>
          <dialog-box  ref="dialogbox" dialogWidth="50%" :showData="showData" :dialogShow="dialogShow" @handleClose='handleClose'  title="物料凭证" componentName="StatementSeeDetailDialog"
              :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
          </dialog-box>
      <div class="action" v-if="haveButton">
        <el-button class="submit" type="primary" size="medium" @click="reject">驳回</el-button>
        <el-button class="submit" type="primary" size="medium" @click="pass">通过</el-button>
      </div>
      <div class="action" v-if="!haveButton&&form.createOnlineInvoice===1&&form.status===1">
        <el-button class="submit" type="primary" size="medium" @click="rejectSub">撤销确认</el-button>
      </div>
  </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField, tableData, tableFieldCy, MaterialData } from './js/statementSeeDetail'
import { request, requestForm } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'StatementSeeDetail',
  data () {
    FlowCtlData.docid = ''
    return {
      tableField: tableField,
      tableFieldCy: tableFieldCy,
      MaterialData: MaterialData,
      tableData: tableData,
      tableDataDetail: [], // 对账明细
      MaterialtableData: [], // 物料汇总
      dialogShow: false,
      formDisabled: true,
      form: {},
      submitData: {},
      showData: {},
      isclose: true,
      isShow: true,
      isShowContent: true,
      isAppr: false,
      haveButton: false,
      xzcyze: 0,
      xzcyzeshow: false
    }
  },
  created () {
    var id = this.$route.query.id
    if (id) {
      this.StatementSeeDetail()
    }
    // this.MaterialDetail()
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.id
        if (id) {
          this.StatementSeeDetail()
        }
      }
    }
  },
  methods: {
    rejectSub () {
      this.$confirm('是否驳回确认', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        requestForm('/api/reconciliation/reconciliationInfo/reject', 'POST', { id: this.form.id }).then((Response) => {
          if (Response.code === '200') {
            requestForm('/api/reconciliation/reconciliationInfo/againSendStatement/' + this.form.id, 'POST').then((Response) => {
              if (Response.code === '200') {
                this.$message({
                  message: '驳回成功',
                  type: 'success'
                })
                this.$router.push('/FinancialManagement/Statement')
              }
            })
          }
        })
      }).catch(() => {
        FlowCtlData.formAlert(this, 'info')
      })
    },
    reject () {
      this.submitData.dzdId = this.form.id
      FlowCtlData.getNodeAction('BH', this.submitData).then((val) => {
        if (val) {
          FlowCtlData.alertBHDialog(this).then(({ value }) => {
            this.submitData.approvalComplete = this.form.approvalComplete
            FlowCtlData.setFormData(FlowCtlData, this.submitData)
            request('/api/reconciliation/reconciliationInfo/feedbackDifferences', 'POST', this.submitData)
            requestForm('/api/reconciliation/reconciliationInfo/updateStatus', 'POST', { id: this.form.id, status: 3 }).then((Response) => {
              if (Response.code === '200') {
                FlowCtlData.setNodeAction(value).then((val) => {
                  FlowCtlData.formAlert(this, 'success')
                  this.$router.push('/FinancialManagement/Statement')
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    pass () {
      this.submitData.dzdId = this.form.id
      this.submitData.infos = []
      for (const item of this.form.reconciliations) {
        this.submitData.infos.push({ id: item.id, revisedAmountIncludingTax: item.revisedAmountIncludingTax })
      }
      FlowCtlData.getNodeAction('TJ', this.submitData).then((val) => {
        if (val) {
          FlowCtlData.alertDialog(this).then(() => {
            this.submitData.approvalComplete = this.form.approvalComplete
            FlowCtlData.setFormData(FlowCtlData, this.submitData)
            request('/api/reconciliation/reconciliationInfo/feedbackDifferences', 'POST', this.submitData)// .then((Response) => {
            // if (Response.code === '200') {
            FlowCtlData.setNodeAction().then((val) => {
              FlowCtlData.formAlert(this, 'success')
              if (FlowCtlData.nextflowactionmsg.nodeMsg.nodeName === '结束') {
                requestForm('/api/reconciliation/reconciliationInfo/updateStatus', 'POST', { id: this.form.id, status: 1 }).then((response) => {
                })
              }
              this.$router.push('/FinancialManagement/Statement')
            })
            // }
            // })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    open () {
      this.isclose = !this.isclose
    },
    openContent () {
      this.isShowContent = !this.isShowContent
    },
    show () {
      this.isShow = !this.isShow
    },
    StatementSeeDetail () {
      var id = this.$route.query.id
      requestForm('/api/reconciliation/reconciliationInfo/queryOne?id=' + id, 'post', '').then((res) => {
        if (res.code === '200') {
          this.form = res.data
          this.tableDataDetail = res.data.reconciliations // 对账明细
          this.MaterialtableData = res.data.materialList // 物料汇总
          this.tableShowitem()
          FlowCtlData.flowid = 'CheckDifferences'
          FlowCtlData.docid = this.form.id
          FlowCtlData.initialize().then(() => {
            if (FlowCtlData.nodeThisData[0].NODEID !== 'flowStart' && FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
              this.haveButton = true
            }
          })
          request(`/api/supplier/basicData/getByErpCode/${this.form.billingPartyCode}`, 'get').then((response) => {
            if (response.code === '200') {
              if (response.data === null || response.data === 'null') {
                this.$message({
                  message: '未找到采购员！',
                  type: 'error'
                })
                return false
              }
              this.submitData.buyer = response.data.buyerCode
            }
          })
        }
      })
      FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.submitData.buyerLeader = val })
    },
    // MaterialDetail () {
    //   var id = this.$route.query.id
    //   requestForm('/api/reconciliation/reconciliationInfo/queryOne?id=' + id, 'post', '').then((res) => {
    //     if (res.code === '200') {
    //       this.tableData = res.data.materialList
    //     }
    //   })
    // },
    tableShowitem () {
      if (this.form.status === 0) {
        // this.tableField.splice(14, 3)
        for (const index in this.tableField) {
          if (this.tableField[index].id === 'xzcy') {
            this.tableField.splice(index, 1)
          }
        }
        this.xzcyzeshow = false
      } else {
        for (const item of this.form.reconciliations) {
          this.xzcyze = parseFloat(this.xzcyze) + (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax))
        }
        this.xzcyze = this.xzcyze.toFixed(2)
        this.xzcyzeshow = true
      }
    },
    diaShow (scope) {
      var number = scope.row.materialVoucherNo
      requestForm('/api/reconciliation/materialDocument/queryVoucherNo?number=' + number, 'post', '').then((res) => {
        if (res.code === '200') {
          this.showData = res.data
          this.dialogShow = true
        }
      })
    },
    handleClose () {
      this.dialogShow = false
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  .disabled {
  .col{
    margin: 3px 0 !important;
  }
}
 .quota{
   margin-top: 20px;
 }
 .showOff{
   float: right;
 }
 .action{
   margin-top: 40px;
 }
</style>
